<template>
  <div class="eye-share--view" v-show="timeSeconds > -1">
    <div class="eye-share--work--container" v-show="timeSeconds > 0" @click.self="$refs.videoContainer.hideControls($event)">
      <div class="icon--container">
        <svg width="64px" height="24px" viewBox="0 0 64 24" version="1.1" xmlns="http://www.w3.org/2000/svg" class="eye__icon">
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Поделиться-3" transform="translate(-156.000000, -100.000000)" fill="#FFFFFF">
              <path d="M164.712042,115.428571 L173.424084,115.428571 L173.424084,108.571429 L164.712042,108.571429 L164.712042,106.857143 L174.094241,106.857143 L174.094241,100 L156,100 L156,124 L174.764398,124 L174.764398,117.142857 L164.712042,117.142857 L164.712042,115.428571 Z M185.151832,100 L175.099476,100 L183.141361,114.742857 L183.141361,124 L191.853403,124 L191.853403,114.742857 L199.895288,100 L189.842932,100 L187.497382,106.857143 L185.151832,100 Z M209.947644,115.428571 L218.659686,115.428571 L218.659686,108.571429 L209.947644,108.571429 L209.947644,106.857143 L219.329843,106.857143 L219.329843,100 L201.235602,100 L201.235602,124 L220,124 L220,117.142857 L209.947644,117.142857 L209.947644,115.428571 Z" id="EYE-Copy-6"></path>
            </g>
          </g>
        </svg>
      </div>
      <div class="video--container">
        <div class="videoplayer">
          <video-item
              v-if="shareData != null"
              :video="camera"
          ></video-item>
        </div>
      </div>
      <div class="timer--container">
        <p class="title__text">ВРЕМЯ ДОСТУПА</p>
        <p class="time__text">{{timeText}}</p>
      </div>
    </div>
    <div class="eye-share--expired--container" v-show="timeSeconds === 0">
      <div class="timer--container">
        <p class="title__text">ВРЕМЯ ИСТЕКЛО</p>
        <p class="time__text">{{timeText}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import VideoItem from "../components/video/VideoItem";

export default {
  name: 'Share',
  components: {VideoItem},
  data () {
    return {
      timeSeconds: -1
    }
  },
  computed: {
    timeText () {
      if (this.timeSeconds < 0) return '00:00'
      // Hours, minutes and seconds
      var mins = ~~((this.timeSeconds % 3600) / 60)
      var secs = ~~this.timeSeconds % 60

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = ''

      ret += '' + (mins < 10 ? '0' : '')
      ret += '' + mins + ':' + (secs < 10 ? '0' : '')
      ret += '' + secs
      return ret
    },
    shareData () {
      return this.$store.getters.getShareData
    },
    uri () {
      const sh = this.shareData
      if (sh.camera == null) return ''

      return sh.camera.uri
    },
    cameraName () {
      const sh = this.shareData
      if (sh.camera == null) return ''

      return sh.camera.name
    },
    camera () {
      if (this.shareData != null){
        const camera = this.shareData.camera
        camera.showAddingControls = false
        camera.isRecorder = 0
        return {...camera}
      }

      return null
    }
  },
  async mounted () {
    this.$store.commit("changeShowHeader", false);
    const token = this.$route.params.token
    await this.$store.dispatch('getShare', { token: token })
    const secondsTo = (moment(this.shareData.expiredAt.date).toDate().getTime() - new Date().getTime()) / 1000
    this.timeSeconds = (secondsTo < 0) ? 0 : secondsTo
    const interval = setInterval(() => {
      if (this.timeSeconds <= 0) {
        clearInterval(interval)
      } else {
        this.timeSeconds = (moment(this.shareData.expiredAt.date).toDate().getTime() - new Date().getTime()) / 1000
      }
    }, 1000)
  },
  beforeDestroy() {
    this.$store.commit("changeShareData",null)
  }
}
</script>

<style scoped lang="scss">
.eye-share--view{
  width: 100%;
  height: 100%;
  background-color: #0D0D0D;
  overflow: hidden;

  .icon--container{
    margin-top: 50px;

    @include media(992){
      margin-top: 30px;
    }
    img{
      width: 64px;
      height: 24px;

      @include media(992){
        width: 50px;
        height: 21px;
      }
    }
  }

  .video--container{
    margin-top: 81px;
    width: 670px;
    height: 400px;
    position: relative;

    @include media(992){
      width: 375px;
      height: 200px;
    }

    .videoplayer{
      width: 100%;
      height: 100%;
    }

  }

  .timer--container{
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media(992){
      margin-top: 0;
      position: absolute;
      bottom: 30px;
    }

    p{
      text-align: center;
      font-family: Roboto, "sans-serif";
      color: white;
      font-size: 13px;
      line-height: 18px;

    }

    .title__text{
      letter-spacing: 0.6px;
    }

    .time__text{
      letter-spacing: 10px;
      width: 74px;
      margin-top: 3px;
    }
  }
}

.eye-share--expired--container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .timer--container{
    @include media(992){
      position: relative;
    }
  }
}

.eye-share--work--container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

